import React from 'react'
import Helmet from 'react-helmet'
import { graphql, PageProps } from 'gatsby'
import styled from '@emotion/styled'

import Layout from '../../layouts'

const aboutText = `Hi there. Nice to meet you!

I’ve spent the last four years studying at the University of St Andrews in Scotland, pursuing an undergraduate degree in computer science and philosophy. Prior to this, I briefly attended the University of Tokyo, studying mathematics and physics, before deciding it wasn’t for me and moving to Scotland.

I’ve tried to structure my life to expose myself to things outside of my comfort zone. Between living in the United States, Japan, and Scotland, as well as studying four different subjects at various points, I think I’ve developed a deeper understanding for how different disciplines approach problems, and how culture and the social structures we are surrounded by shape our thinking. `

// I’m also an avid traveller—I’ve travelled from Iceland to Thailand to Italy to Tibet—and I love getting a feel for what life is like at each of these different countries. As someone who has had immersive experiences across three different cultures, I am acutely aware of the superficiality of any experiences you may have as a traveller who’s life is not grounded in the local area in any way, but I think the same experience has also led me to understand that sometimes the vantage point of an outsider is valuable and lets you see things that may be invisible to someone who’s norms are shaped by their surroundings.

// I also love photography—by seeing the world with a camera in your hand, you come to notice how light and shadow color the light and learn how much interpretation the human eye does when all you think you’re doing is passively seeing—something that has also informed my studies in computer vision, when what you are typically dealing with is raw imagery and you have to recreate some of the preprocessing that the human eye does.

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: flex-start;
  max-width: 800px;
  margin: 80px auto;

  @media (max-width: 640px) {
    display: block;
  }
`

const HeaderWrapper = styled.div`
  width: 40%;
  padding-left: 40px;
  padding-right: 20px;
  @media (max-width: 640px) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`

const BodyWrapper = styled.div`
  width: 60%;
  padding-top: 24px;
  @media (max-width: 640px) {
    width: 100%;
  }
`

const HeaderText = styled.h1`
  font-family: Charter, Georgia, 'Times New Roman', Times, serif;
  font-weight: 400;
  font-size: 48px;
  @media (max-width: 640px) {
    font-size: 32px;
  }
`

const generateExcerpt = (s: string, len: number) => {
  const tmp = s
    .replace(/\n/g, ' ')
    .replace(/\s+/g, ' ')
    .substring(0, len + 1)
  return tmp.substring(0, Math.min(tmp.lastIndexOf(' '), len)).concat('…')
}

interface AboutPageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        title: string
      }
    }
    file: {
      publicURL: string
    }
  }
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    file(relativePath: { eq: "cv.pdf" }) {
      publicURL
    }
  }
`

const AboutPage = ({ data }: AboutPageProps) => {
  const siteTitle = data.site.siteMetadata.title
  const desc = generateExcerpt(aboutText, 160)
  return (
    <Layout>
      <Helmet>
        <title>{`About Me | ${siteTitle}`}</title>
        <meta name="description" content={desc} />

        {/* Metadata for Open Graph */}
        <meta property="og:title" content={siteTitle} />
        <meta property="og:description" content={desc} />
      </Helmet>
      <Wrapper>
        <HeaderWrapper>
          <HeaderText>{'About Me'}</HeaderText>
        </HeaderWrapper>
        <BodyWrapper>
          {aboutText.split(/\n\s*\n/).map((line, index) => (
            <p key={index}>{line}</p>
          ))}
          {/* <p>
            {'My CV is available '}
            <a href={data.file.publicURL}>here</a>
            {'.'}
          </p> */}
        </BodyWrapper>
      </Wrapper>
    </Layout>
  )
}

export default AboutPage
